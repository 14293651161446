<template>
  <div class="totalBody">
    <div class="headerBox">
      <p>捷思联想检验智能管控系统-看板</p>
      <div class="currentDate">{{ nowTime }}</div>
    </div>

    <el-row class="rows">
      <el-col :span="24">
        <div class="reportBox reportBox1">
          <h4 class="chartTitle">排产状况</h4>
          <div class="tableBox">
            <el-table :data="tableData">
              <el-table-column align="center" prop="name" label="检验类型"></el-table-column>
              <el-table-column align="center" label="检测【1】线">
                <el-table-column align="center" prop="val1" label="配置产能"></el-table-column>
                <el-table-column align="center" prop="val1" label="已投产"></el-table-column>
              </el-table-column>
              <el-table-column width="50px"></el-table-column>
              <el-table-column align="center" label="检测【2】线">
                <el-table-column align="center" prop="val1" label="配置产能"></el-table-column>
                <el-table-column align="center" prop="val1" label="已投产"></el-table-column>
              </el-table-column>
              <el-table-column width="50px"></el-table-column>
              <el-table-column align="center" label="检测【3】线">
                <el-table-column align="center" prop="val1" label="配置产能"></el-table-column>
                <el-table-column align="center" prop="val1" label="已投产"></el-table-column>
              </el-table-column>
              <el-table-column width="50px"></el-table-column>
              <el-table-column align="center" label="检测【4】线">
                <el-table-column align="center" prop="val1" label="配置产能"></el-table-column>
                <el-table-column align="center" prop="val1" label="已投产"></el-table-column>
              </el-table-column>
              <el-table-column width="50px"></el-table-column>
              <el-table-column align="center" label="检测【5】线">
                <el-table-column align="center" prop="val1" label="配置产能"></el-table-column>
                <el-table-column align="center" prop="val1" label="已投产"></el-table-column>
              </el-table-column>
              <el-table-column width="50px"></el-table-column>
              <el-table-column align="center" label="合计">
                <el-table-column align="center" prop="val1" label="配置产能"></el-table-column>
                <el-table-column align="center" prop="val1" label="已投产"></el-table-column>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-col>
    </el-row>

    <el-row class="rows" :gutter="30">
      <el-col :span="9">
        <div class="reportBox reportBox2">
          <h4 class="chartTitle">人员产出状况</h4>
          <div class="tableBox">
            <dv-scroll-board
                :rowNum=24
                :config="dataV.config"
                style="width:100%;height:550px;"/>
          </div>
        </div>
      </el-col>
      <el-col :span="15">
        <el-row>
          <div class="reportBox reportBox3">
            <h4 class="chartTitle">电性能占比</h4>
            <div id="lineChat" class="lineChat"></div>
          </div>
        </el-row>
        <el-row style="padding-top: 30px">
          <div class="reportBox reportBox4">
            <h4 class="chartTitle">产出状况</h4>
            <div id="lineChat2" class="lineChat2"></div>
          </div>
        </el-row>
      </el-col>
    </el-row>


  </div>
</template>

<script>
import {currentDate} from "@/utils/common";
import * as echarts from 'echarts';

export default {
  name: "Report1",
  data() {
    return {
      nowTime: currentDate(),
      tableData: [{
        name: '外观',
        val1: 10,
      }, {
        name: '电性能',
        val1: 10,
      }, {
        name: '金钥匙',
        val1: 10,
      }],
      dataV: {
        config: {},
        header: ['姓名', '分拣', '外观', '电性能', '金钥匙', '包装', '归还', '合计'],
        align: ['center', 'center', 'center', 'center', 'center', 'center', 'center', 'center'],
        data: [
          ['李四四', 18, 20, 50, 20, 12, 23, 43],
          ['张三三', 18, 20, 50, 20, 12, 23, 43],
          ['李四四', 18, 20, 50, 20, 12, 23, 43],
          ['李四四', 18, 20, 50, 20, 12, 23, 43],
          ['李四四', 18, 20, 50, 20, 12, 23, 43],
          ['李四四', 18, 20, 50, 20, 12, 23, 43],
          ['张三三', 18, 20, 50, 20, 12, 23, 43],
          ['李四四', 18, 20, 50, 20, 12, 23, 43],
          ['李四四', 18, 20, 50, 20, 12, 23, 43],
          ['李四四', 18, 20, 50, 20, 12, 23, 43],
          ['李四四', 18, 20, 50, 20, 12, 23, 43],
          ['张三三', 18, 20, 50, 20, 12, 23, 43],
          ['李四四', 18, 20, 50, 20, 12, 23, 43],
          ['李四四', 18, 20, 50, 20, 12, 23, 43],
          ['李四四', 18, 20, 50, 20, 12, 23, 43],
          ['李四四', 18, 20, 50, 20, 12, 23, 43],
          ['张三三', 18, 20, 50, 20, 12, 23, 43],
          ['李四四', 18, 20, 50, 20, 12, 23, 43],
          ['李四四', 18, 20, 50, 20, 12, 23, 43],
          ['李四四', 18, 20, 50, 20, 12, 23, 43],
        ],
      },
      lineChat2: {
        xdata: ['2021-04-30', '2021-05-01', '2021-05-02', '2021-05-03', '2021-05-04', '2021-05-05', '2021-05-06', '2021-05-07', '2021-05-08', '2021-05-09'],
        ydata1: [10, 20, 30, 20, 10, 23, 4, 53, 34, 54],
        ydata2: [30, 10, 20, 30, 20, 10, 23, 4, 53, 34],
      },
      lineChat: {
        xdata: ['2021-04-30', '2021-05-01', '2021-05-02', '2021-05-03', '2021-05-04', '2021-05-05', '2021-05-06', '2021-05-07', '2021-05-08', '2021-05-09'],
        ydata1: [10, 20, 30, 20, 10, 23, 4, 53, 34, 54],
      }
    }
  },
  mounted() {
    document.title = '看板'
    setInterval(() => this.nowTime = currentDate(), 1000);
    this.drawLineChart()
    this.drawLineChart2()
    this.dataV.config = {
      data: this.dataV.data,
      header: this.dataV.header,
      rowNum: 12,
      headerBGC: 'transparent',
      oddRowBGC: 'transparent',
      evenRowBGC: 'rgba(30, 176, 252, .3)',
      align: this.dataV.align,
    }

  },
  methods: {
    drawLineChart2() {
      let chart2 = echarts.init(document.getElementById('lineChat2'))
      let option = {
        color: ['#F89446', '#17E53B'],
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          left: '50',
          data: ['已投产', '已完成'],
          textStyle: {
            color: '#fff'
          }
        },
        grid: {
          x: 60,
          y: 40,
          x2: 50,
          y2: 40
        },

        xAxis: {
          type: 'category',
          boundaryGap: true,
          axisTick: {
            alignWithLabel: true
          },
          axisLine: {
            lineStyle: {
              color: '#c0ddfd'
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#c0ddfd'
            }
          },
          data: this.lineChat2.xdata
        },
        yAxis: {
          type: 'value',
          axisTick: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: '#c0ddfd'
            }
          },
          axisLabel: {
            textStyle: {
              color: '#c0ddfd'
            }
          },
          // 设置网格线颜色
          splitLine: {
            show: true,
            lineStyle: {
              color: '#636363',
              width: 1,
              type: 'solid'
            },
          },
        },
        series: [
          {
            name: '已投产',
            type: 'line',
            data: this.lineChat2.ydata1
          },
          {
            name: '已完成',
            type: 'line',
            data: this.lineChat2.ydata2
          },
        ]
      };
      // 使用刚指定的配置项和数据显示图表。
      chart2.setOption(option);
    },
    drawLineChart() {
      let chart = echarts.init(document.getElementById('lineChat'))
      let option = {
        color: ['#1eb0fc'],
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          left: '50',
          data: ['电性能占比'],
          textStyle: {
            color: '#fff'
          }
        },
        grid: {
          x: 60,
          y: 40,
          x2: 50,
          y2: 40
        },

        xAxis: {
          type: 'category',
          boundaryGap: true,
          axisTick: {
            alignWithLabel: true
          },
          axisLine: {
            lineStyle: {
              color: '#c0ddfd'
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#c0ddfd'
            }
          },
          data: this.lineChat.xdata
        },
        yAxis: {
          type: 'value',
          axisTick: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: '#c0ddfd'
            }
          },
          axisLabel: {
            formatter: '{value}%',
            textStyle: {
              color: '#c0ddfd'
            }
          },
          // 设置网格线颜色
          splitLine: {
            show: true,
            lineStyle: {
              color: '#636363',
              width: 1,
              type: 'solid'
            },
          },
        },
        series: [
          {
            name: '电性能占比',
            type: 'line',
            data: this.lineChat.ydata1
          },
        ]
      };
      // 使用刚指定的配置项和数据显示图表。
      chart.setOption(option);
    },
  }
}
</script>

<style scoped>
.totalBody {
  color: #ffffff;
  min-width: 1900px;
  min-height: 1080px;
  background: url('../../assets/images/report/bg_report.png') no-repeat;
  background-size: cover; /* 拉伸图片覆盖整个区域 */
  padding: 0px;
  overflow-x: hidden;
  overflow-y: auto;
}

.headerBox {
  width: 100%;
  height: 89px;
  background: url('../../assets/images/report/header.png') no-repeat;
  background-size: 100% 100%;
  position: relative;
  margin-bottom: 20px;
  margin-top: 5px;
}

.headerBox > p {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  margin: 0 0 10px;
}

.headerBox > div {
  position: absolute;
  top: 0;
}

.currentDate {
  font-size: 14px;
  right: 30px;
  line-height: 35px;
}

.rows {
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 30px;
}

.chartTitle {
  font-weight: normal;
  font-size: 20px;
  margin-top: 0px;
  margin-bottom: 10px;
  text-align: center;
  color: #01c4f7;
}

.chartTitle:before {
  content: url('../../assets/images/report/title_before.png');
}

.chartTitle:after {
  content: url('../../assets/images/report/title_after.png');
}

.reportBox {
  background: url('../../assets/images/report/report1_bg.png') no-repeat;
  background-size: 100% 100%;
}

.reportBox1 {
  height: 300px;
}

.reportBox2 {
  height: 600px;
}

.reportBox3 {
  height: 250px;
}

.reportBox4 {
  height: 320px;
}

.lineChat {
  height: 220px;
}

.lineChat2 {
  height: 290px;
}

.dv-scroll-board {
  color: #1eb0fc;
}

.dv-scroll-board /deep/ .header {
  font-size: 16px;
}

.tableBox /deep/ .el-table tr {
  background-color: transparent;
  border: none;
}

.tableBox /deep/ .el-table th, .el-table tr, .el-table td {
  background-color: transparent;
  border-style: none;
}

.tableBox /deep/ table tbody tr:nth-child(odd) {
  background: rgba(30, 176, 252, .3);
  border: none;
}

.tableBox /deep/ .el-table, .el-table__expanded-cell {
  background-color: transparent;
  border: none;
}

.tableBox /deep/ .el-table--enable-row-transition .el-table__body td, .el-table .cell {
  background-color: transparent;
  border: none;
}

.tableBox /deep/ .el-table td, thead {
  color: #1eb0fc;
  font-size: 16px;
}

.tableBox /deep/ .el-table th, thead {
  color: #1eb0fc;
  font-size: 16px;
  padding: 5px 0;
}

.el-table--border::after, .el-table--group::after, .el-table::before {
  display: none;
}

</style>